import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
//assets
import MarkIcon from '../../images/mark_icon.png';
//layout
import RootLayout from './../../layouts/RootLayout';

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-block: 40vh;

    img {
        width: 40px;
        height: 40px;
    }
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    font-family: 'Rubik';
    margin-block: 20px;
    color: #262626;

    @media screen and (max-width: 500px) {
        font-size: 20px;
    }
`;

const SubTitle = styled.h4`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: 'Rubik';
    text-align: center;
    color: #8c8c8c;
    width: 403px;

    @media screen and (max-width: 500px) {
        width: 237px;
    }
`;

function Confirmation() {
    return (
        <>
            <Helmet
                style={[
                    {
                        cssText: `
            .osano-cm-window__widget {
                display:none !important;
            }

        `,
                    },
                ]}
            />
            <RootLayout>
                <Container>
                    <img src={MarkIcon} alt="Thank you Icon" />
                    <Title>Your Demo is Confirmed</Title>
                    <SubTitle>
                        A calender invite has been sent to your email address with the full booking
                        details.
                    </SubTitle>
                </Container>
            </RootLayout>
        </>
    );
}

export default Confirmation;
